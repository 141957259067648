@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: rgb(0,0,0);
    background: -moz-linear-gradient(45deg, rgba(0,0,0,1) 0%, rgba(17,37,68,1) 52%, rgba(1,43,109,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(0,0,0,1) 0%, rgba(17,37,68,1) 52%, rgba(1,43,109,1) 100%);
    background: linear-gradient(45deg, rgba(0,0,0,1) 0%, rgba(17,37,68,1) 52%, rgba(1,43,109,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ff9100",GradientType=1);
    color: white;
}

.custom-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 200px;
}

.custom-shape .shape-fill {
    fill: #FFFFFF;
}

body::-webkit-scrollbar {
    display: none;
  }
  
  body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .page__bg::before {
      background: url('./assets/dot-c.png') repeat-x;
      height: 100%;
      background-position: center;
      background-attachment: fixed;
      background-size: contain;
      opacity: 0.05;
  }
  